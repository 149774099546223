import lt from 'semver/functions/lt'
import eq from 'semver/functions/eq'
import config from '../config/Amplify';

/**
 * @typedef {Object} FeatureFlags
 * @property {boolean} [hideUserView]
 * @property {boolean} [shouldShowUpdatedSystemView]
 */

class FeatureFlagService {
  static instance = null

  _actionsSupportVersion = '5.11.1'
  _encryptionSupportVersion = '5.13.1'
  _flowsSupportMainVersion = '5.13.0'
  _flowsSupportBuildVersion = 99
  
  /** @type {FeatureFlags} */
  _featureFlags = {}
  
  constructor() {
    // noinspection ES6ModulesDependencies
    if (FeatureFlagService.instance) {
        // noinspection ES6ModulesDependencies
        return FeatureFlagService.instance
    }

    /** @type {FeatureFlags} */
    this._featureFlags = config.FeatureFlags;
    FeatureFlagService.instance = this;
  }

  static getInstance() {
    if (!FeatureFlagService.instance) {
      FeatureFlagService.instance = new FeatureFlagService();
    }
    return FeatureFlagService.instance;
  }

  /**
   * Returns all feature flags from configuration
   * @returns {FeatureFlags} Object containing all feature flags
   */
  getFeatureFlags() {
    return this._featureFlags
  }

  _isProduction () {
    return process.env.REACT_APP_STAGE === 'Prod'
  }

  _isTestingEnv () {
    return (
      window.location.host.match(/gmg-dev/) 
      || window.location.host.match(/gmg-qa/) 
      || window.location.host.match(/gmg-test/) 
      || window.location.host.match(/gmg-preprod/)
    )
  }

  _isLocalDevEnv () {
    return !this._isProduction() && !this._isTestingEnv()
  }

  _areActionsSupportedByVersion (actionObj, sites) {
    // Now compare the version
    for (let i = 0, l = sites.length; i < l; i++) {
      if (sites[ i ].site === actionObj.site) {
        let cpVersionArr = sites[ i ].version.split('.')
        cpVersionArr.pop()

        let versionAsSemver = cpVersionArr.join('.')

        if (lt(versionAsSemver, this._actionsSupportVersion)) {
          return false
        }
      }
    }

    return true
  }

  areJobActionsSupported (job, sites) {
    // Not loaded, simply return true, as this is a feature for compatibility
    if (!sites) return true
    // Override can be used for Testing and QA, so this feature can be tested if needed
    if (window.ColorProof.jobActionsEnabled) return true
    
    if (this._isLocalDevEnv()) return true

    return this._areActionsSupportedByVersion(job, sites)
  }

  arePrinterActionsSupported (printer, sites) {
    // Not loaded, simply return true, as this is a feature for compatibility
    if (!sites) return true
    // Override can be used for Testing and QA, so this feature can be tested if needed
    if (window.ColorProof.printerActionsEnabled) return true
    
    if (this._isLocalDevEnv()) return true

    return this._areActionsSupportedByVersion(printer, sites)
  }

  areFlowActionsSupported (site) {
    const versionParts = this.createVersionParts(site.version)

    if (lt(versionParts.mainV, this._flowsSupportMainVersion)) return false

    if (eq(versionParts.mainV, this._flowsSupportMainVersion) && parseInt(versionParts.buildV) < this._flowsSupportBuildVersion) {
          return false
    }

    return true
  }

  isFilesEncryptionSupported (site) {
    const versionParts = this.createVersionParts(site.version)

    if (lt(versionParts.mainV, this._encryptionSupportVersion)) return false

    return true
  }

  createVersionParts (version) {
    const versionParts = version.split('.')
    const buildV = versionParts.pop()
    const mainV = versionParts.join('.')

    return { mainV, buildV }
  }

}

export { FeatureFlagService }
