import { AUTH_TYPE } from 'aws-appsync-auth-link'
import { AWSAppSyncClient, createAppSyncLink } from 'aws-appsync'
import { setContext } from "apollo-link-context"
import { ApolloLink } from "apollo-link"
import { createHttpLink } from "apollo-link-http"
import { defaultDataIdFromObject } from 'apollo-cache-inmemory'
import AppSyncConfig from './AppSync'
import CpGoDocDbApiConfig from './CpGoDocDbAppsync'
import { loadAuthIdToken as loadAuthTokenFromLocalStorage } from './../../store/localStorage'
import { GMGAuth } from './GMGAuth'

const getUserCredentials = async () => {
  return await GMGAuth.getInstance().currentCredentials();
}

const getAccessToken = async () => {
    return await GMGAuth.getInstance().getAccessToken();
  }

const AppSyncConfigLink = {
  region: AppSyncConfig.region,
  url: AppSyncConfig.url,
  auth: {
      type: AUTH_TYPE.AWS_IAM,
      credentials: getUserCredentials
  },
  fetchPolicy: 'network-only'
};

const authLink = setContext((_, {headers, ...context}) => {
  const idToken = loadAuthTokenFromLocalStorage();
  return {
    headers: {
      ...headers,
      ...(idToken ? {jwt: idToken} : {}),
    },
    ...context,
  };
});

const httpLink = createHttpLink({uri: AppSyncConfig.url});
const link = ApolloLink.from([authLink, httpLink]);

export const client = new AWSAppSyncClient({
  AppSyncConfigLink,
  cacheOptions: {
    dataIdFromObject: obj => {
      switch (obj.__typename) {
        case 'Job':
          return `Job:${obj.id}`
        default:
          return defaultDataIdFromObject(obj) // fall back to default handling
      }
    }
  }},
  {
    link: createAppSyncLink({
    ...AppSyncConfigLink,
    resultsFetcherLink: link,
    })
  }
)

export const cpGoDocDbApi = new AWSAppSyncClient({
    ...CpGoDocDbApiConfig,
    disableOffline: true,
    fetchPolicy: 'network-only',
    auth: {
      type: AUTH_TYPE.OPENID_CONNECT,
      jwtToken: getAccessToken
    },
    cacheOptions: {
      addTypename: false  // strip out typename __typename from the response
    }
  }
)