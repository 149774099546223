import { Auth } from 'aws-amplify';

export const getAccessAndIdToken = async () => {
    try {
        const session = await Auth.currentSession();
        const idToken = session.getIdToken().getJwtToken();
        const accessToken = session.getAccessToken().getJwtToken();

        return { idToken, accessToken };
    } catch {
        console.error('could not get current session');
        return undefined;
    }
};