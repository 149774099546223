export const GET_FLOWS = 'GET_FLOWS'
export const GET_FLOWS_SUCCESS = 'GET_FLOWS_SUCCESS'
export const GET_FLOWS_FAILURE = 'GET_FLOWS_FAILURE'

export const GET_CUSTOMER_PAIR_INFO = 'GET_CUSTOMER_PAIR_INFO'
export const GET_CUSTOMER_PAIR_INFO_SUCCESS = 'GET_CUSTOMER_PAIR_INFO_SUCCESS'
export const GET_CUSTOMER_PAIR_INFO_FAILURE = 'GET_CUSTOMER_PAIR_INFO_FAILURE'

export const SUBSCRIBE_TO_CUSTOMER_UPDATE_CP_SITE = 'SUBSCRIBE_TO_CUSTOMER_UPDATE_CP_SITE'
export const SUBSCRIBE_TO_CUSTOMER_UPDATE_CP_SITE_SUCCESS = 'SUBSCRIBE_TO_CUSTOMER_UPDATE_CP_SITE_SUCCESS'
export const SUBSCRIBE_TO_CUSTOMER_UPDATE_CP_SITE_FAILURE = 'SUBSCRIBE_TO_CUSTOMER_UPDATE_CP_SITE_FAILURE'

export const SUBSCRIBE_TO_LINKED_CP_INFO_UPDATE = 'SUBSCRIBE_TO_LINKED_CP_INFO_UPDATE'
export const SUBSCRIBE_TO_LINKED_CP_INFO_UPDATE_SUCCESS = 'SUBSCRIBE_TO_LINKED_CP_INFO_UPDATE_SUCCESS'
export const SUBSCRIBE_TO_LINKED_CP_INFO_UPDATE_FAILURE = 'SUBSCRIBE_TO_LINKED_CP_INFO_UPDATE_FAILURE'

export const SUBSCRIBE_TO_FLOWS_UPDATE = 'SUBSCRIBE_TO_FLOWS_UPDATE'
export const SUBSCRIBE_TO_FLOWS_UPDATE_SUCCESS = 'SUBSCRIBE_TO_FLOWS_UPDATE_SUCCESS'
export const SUBSCRIBE_TO_FLOWS_UPDATE_FAILURE = 'SUBSCRIBE_TO_FLOWS_UPDATE_FAILURE'

export const CHANGE_ALIAS = 'CHANGE_ALIAS'
export const CHANGE_ALIAS_SUCCESS = 'CHANGE_ALIAS_SUCCESS'
export const CHANGE_ALIAS_FAILURE = 'CHANGE_ALIAS_FAILURE'

export const CHANGE_COLOR_HUB = 'CHANGE_COLOR_HUB'
export const CHANGE_COLOR_HUB_SUCCESS = 'CHANGE_COLOR_HUB_SUCCESS'
export const CHANGE_COLOR_HUB_FAILURE = 'CHANGE_COLOR_HUB_FAILURE'

export const DELETE_CP_CUSTOMER = 'DELETE_CP_CUSTOMER'
export const DELETE_CP_CUSTOMER_SUCCESS = 'DELETE_CP_CUSTOMER_SUCCESS'
export const DELETE_CP_CUSTOMER_FAILURE = 'DELETE_CP_CUSTOMER_FAILURE'

export const DELETE_PENDING_CP_CUSTOMER = 'DELETE_PENDING_CP_CUSTOMER'
export const DELETE_PENDING_CP_CUSTOMER_SUCCESS = 'DELETE_PENDING_CP_CUSTOMER_SUCCESS'
export const DELETE_PENDING_CP_CUSTOMER_FAILURE = 'DELETE_PENDING_CP_CUSTOMER_FAILURE'

export const UPDATE_CP_CUSTOMER_PAIR = 'UPDATE_CP_CUSTOMER_PAIR'
export const UPDATE_CP_CUSTOMER_PAIR_SUCCESS = 'UPDATE_CP_CUSTOMER_PAIR_SUCCESS'
export const UPDATE_CP_CUSTOMER_PAIR_FAILURE = 'UPDATE_CP_CUSTOMER_PAIR_FAILURE'

export const ADD_FLOW = 'ADD_FLOW'
export const ADD_FLOW_SUCCESS = 'ADD_FLOW_SUCCESS'
export const ADD_FLOW_FAILURE = 'ADD_FLOW_FAILURE'

export const DELETE_FLOW = 'DELETE_FLOW'
export const DELETE_FLOW_SUCCESS = 'DELETE_FLOW_SUCCESS'
export const DELETE_FLOW_FAILURE = 'DELETE_FLOW_FAILURE'

export const INVITE_USER = 'INVITE_USER'
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS'
export const INVITE_USER_FAILURE = 'INVITE_USER_FAILURE'

export const GET_LICENSESYSTEMS_NEW_BE = 'GET_LICENSESYSTEMS_NEW_BE'
export const GET_LICENSESYSTEMS_NEW_BE_SUCCESS = 'GET_LICENSESYSTEMS_NEW_BE_SUCCESS'
export const GET_LICENSESYSTEMS_NEW_BE_FAILURE = 'GET_LICENSESYSTEMS_NEW_BE_FAILURE'

export const CLEAR_SHARED_DATA = 'CLEAR_SHARED_DATA'