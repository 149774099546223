import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tippy from '@tippy.js/react';
import CheckmarkIcon from '../../../../../assets/icons/CheckmarkIcon';
import CheckboxIcon from '../../../../../assets/icons/CheckboxIcon';
import CheckboxCheckedIcon from '../../../../../assets/icons/CheckboxCheckedIcon';
import CloseIcon from '../../../../../assets/icons/CloseIcon';
import ServiceContext from '../../../../shared/Services/ServiceContext';
import PermissionTypes from '../../../../shared/duck/types/permissionTypes';
import PermissionsValidator from '../../../../shared/duck/helpers/permissionsValidator';
import CpTimeAgo from '../../../../shared/Components/Date/CpTimeAgo';
import Popover from '../../../../shared/Components/Messaging/Popover';
import { changeAlias, changeColorHub } from '../../../../shared/duck/actions/pairingInfoActions';
import { getColorHubCheckboxClickEvent } from '../../../../shared/Services/tracking';

class SitesList extends React.Component {
    static contextType = ServiceContext;

    state = { alias: '' };
    changeSiteAlias = this.changeSiteAlias.bind(this);
    onAliasKeyDown = this.onAliasKeyDown.bind(this);

    shoudRenderOldSystemView = this.context.featureFlagService.getFeatureFlags().shouldShowUpdatedSystemView === false;

    onAliasKeyDown(event, site) {
        if (event.keyCode === 13) {
            this.changeSiteAlias(event, site);
        }
    }

    async changeSiteAlias(event, site) {
        let aliasName = null;
        if (event.target.value)
            aliasName = event.target.value;

        const customerId = this.props.auth.currentUser.username;
        await this.props.changeAlias(site.cpId, customerId, site.dongleId, aliasName);

        this.setState({ alias: '' });
    }

    async changeSiteColorHub(cpId, checked, site) {
        this.context.trackingService.trackEvent(getColorHubCheckboxClickEvent());
        console.log('getColorHubCheckboxClickEvent has been calles...');
        const customerId = this.props.auth.currentUser.username;
        await this.props.changeColorHub(cpId, customerId, site.dongleId, !checked);
    }

    openInputBox(dongleId, site, event) {
        this.setState({
            alias: dongleId + '_' + site
        });
    }

    setSiteOnlineStatus(site) {
        if (site.hasOwnProperty('isOnline') && site.isOnline != null) {
            return site.isOnline ?
                this.props.messages['cpSitesUser.online'] : this.props.messages['cpSitesUser.offline'];
        }

        if (!site.lastSeen)
            return this.props.messages['unknown'];

        const dateNow = new Date();
        const lastSeenInt = parseInt(site.lastSeen);
        if (isNaN(lastSeenInt)) {
            // eslint-disable-next-line
            throw 'Could not parse timestamp from server.';
        }

        const dateLastSeen = new Date(lastSeenInt);
        if (dateNow.getTime() - dateLastSeen.getTime() < 300000)
            return this.props.messages['cpSitesUser.online'];

        return this.props.messages['cpSitesUser.offline'];
    }

    render() {
        const textId = 'cpSitesUser.noSites';
        const defaultText = 'No Sites';
        const { classes } = this.props;

        if (this.props.error) {
            return <TableRow />;
        }
        if (!this.props.sites) {
            return (
                <TableRow>
                    <TableCell className={classes.tableCellSpacerMobile} />
                    <TableCell className={classNames(classes.tableCell, classes.noData)}>
                        <span className={'noData'}>
                            <FormattedMessage id={textId} defaultMessage={defaultText} />
                        </span>
                    </TableCell>
                    <TableCell className={classes.tableCellSpacerMobile} />
                </TableRow>
            );
        }

        const validator = new PermissionsValidator(this.props.permissions);
        return this.props.sites.map((site, index) => {
            return (
                <TableRow key={`sites-row-${site.dongleId}`}>
                    <TableCell className={classes.tableCellSpacerMobile} />
                    <TableCell
                        key={`sites-col-alias-${site.cpId}`}
                        className={classNames(
                            classes.tableCell,
                            classes.settingTableCellWidth
                        )}
                    >
                        {/* // if feature flag enabled than should be no cursor */}
                        <span className={classNames({ [classes.cursorPointer]: this.shoudRenderOldSystemView })}>
                            <div
                                style={
                                    site.alias ||
                                        site.dongleId + '_' + site.site === this.state.alias
                                        ? { display: 'block' }
                                        : { display: 'none' }
                                }
                            >
                                <input
                                    key={['alias', site.dongleId].join('_')}
                                    type="text"
                                    defaultValue={site.alias}
                                    onKeyDown={e => { this.onAliasKeyDown(e, site); }}
                                    onBlur={e => {
                                        if (e.target.value !== site.alias) {
                                            this.changeSiteAlias(e, site);
                                        }
                                    }}
                                    autoFocus={this.lastFocus === site.dongleId + '_' + site.site}
                                    onClick={() => {
                                        // in new system view there is no click here
                                        if (!this.shoudRenderOldSystemView) {
                                            return;
                                        }
                                        const lastFocusIdentifier = site.dongleId + '_' + site.site;
                                        this.lastFocus = lastFocusIdentifier;
                                        window.setTimeout(function () {
                                            document.getElementById(lastFocusIdentifier).focus();
                                        }, 0);
                                    }}
                                    name={site.dongleId}
                                    className={classes.aliasInputBox}
                                    placeholder={'Enter Alias'}
                                    autoComplete="off"
                                    id={site.dongleId + '_' + site.site}
                                    disabled={
                                        // in new system view input is disabled
                                        this.shoudRenderOldSystemView
                                            ? !validator.hasUserPermissionForCp(site.cpId, PermissionTypes.ManageColorProofGo) || !site.licensed
                                            : true
                                    }
                                />
                                <br />
                                <span className={classes.subline} id={site.dongleId}>
                                    {/* bottom text  === computername */}
                                    {site.site}
                                </span>
                            </div>

                            {/* /we render this component just in old view not with the updated version */}
                            {this.shoudRenderOldSystemView &&
                                <span
                                    style={
                                        site.alias ||
                                            site.dongleId + '_' + site.site === this.state.alias
                                            ? { display: 'none' }
                                            : { display: 'block' }
                                    }
                                    type="span"
                                    onClick={e => {
                                        if (validator.hasUserPermissionForCp(site.cpId, PermissionTypes.ManageColorProofGo)) {
                                            this.openInputBox(site.dongleId, site.site, e);
                                            window.setTimeout(function () {
                                                document
                                                    .getElementById(site.dongleId + '_' + site.site)
                                                    .focus();
                                            }, 100);
                                        }
                                    }}
                                    id={site.dongleId}
                                >
                                    {validator.hasUserPermissionForCp(site.cpId, PermissionTypes.ManageColorProofGo) && site.licensed ?
                                        (
                                            <Tippy
                                                key={index}
                                                content={
                                                    <FormattedMessage
                                                        id="sites.tooltip"
                                                        defaultMessage="You can change this name by clicking on it."
                                                    />
                                                }
                                                arrow={true}
                                                arrowType="sharp"
                                                animation="fade"
                                                placement={'right-start'}
                                                className={classes.tooltipsWithWidth}
                                                popperOptions={{
                                                    placement: 'right-start',
                                                    modifiers: {
                                                        preventOverflow: {
                                                            enabled: false
                                                        },
                                                        hide: {
                                                            enabled: false
                                                        }
                                                    }
                                                }}
                                            >
                                                <span>{site.site}</span>
                                            </Tippy>
                                        ) : (
                                            <span>{site.site}</span>
                                        )}
                                </span>
                            }
                        </span>
                    </TableCell>
                    <TableCell
                        key={`sites-col-status-${site.cpId}`}
                        className={classNames(
                            classes.tableCell,
                            classes.settingTableCellWidth
                        )}
                    >
                        {this.setSiteOnlineStatus(site)}
                    </TableCell>
                    <TableCell
                        key={`sites-col-last-seen-${site.lastSeen}`}
                        className={classNames(
                            classes.tableCell,
                            classes.noLeftPadding,
                            classes.showWebTableCell
                        )}
                    >
                        <Popover
                            content={new Date(parseInt(site.lastSeen)).toJSON()}
                            position="top"
                            distance="10"
                        >
                            <span>
                                {!!site.lastSeen
                                    ? <CpTimeAgo date={new Date(parseInt(site.lastSeen)).toString()}/> 
                                    : <span>-</span> 
                                }
                            </span>
                        </Popover>
                    </TableCell>

                    <TableCell
                        key={`sites-col-licensed-${site.cpId}`}
                        className={classNames(classes.tableCell)}
                        align={'left'}
                    >
                        {site.licensed
                            ? <Popover content={site.dongleId} position="top" distance="15">
                                <span>
                                    <CheckmarkIcon className={classNames(classes.iconSizeCheckmark, classes.green)} />
                                </span>
                            </Popover>
                            : <CloseIcon className={classNames(classes.iconSizeCross, classes.red)} />
                        }
                    </TableCell>

                    {/* /we render this component just in old view not with the updated version */}
                    {this.shoudRenderOldSystemView &&
                        <TableCell
                            key={`sites-col-colorhub-${site.cpId}`}
                            className={classNames(classes.tableCell, {
                                [classes.hideFeature]: !validator.hasUserFlowManagementPermissionForAtLeastOneCp(),
                                [classes.tableCellHideMobile]: true
                            })}
                            align={'left'}
                        >
                            {site.isHub === null || site.isHub === false ? (
                                <CheckboxIcon
                                    name={site.dongleId}
                                    onClick={() => {
                                        if (validator.hasUserPermissionForCp(site.cpId, PermissionTypes.ManageFlow)) {
                                            this.changeSiteColorHub(site.cpId, false, site);
                                        }
                                    }}
                                    className={classNames(
                                        {
                                            [classes.disabled]: !validator.hasUserPermissionForCp(site.cpId, PermissionTypes.ManageFlow)
                                        },
                                        classes.iconSizeCheckmark
                                    )}
                                />
                            ) : (
                                <CheckboxCheckedIcon
                                    name={site.dongleId}
                                    onClick={() => {
                                        if (validator.hasUserPermissionForCp(site.cpId, PermissionTypes.ManageFlow)) {
                                            this.changeSiteColorHub(site.cpId, true, site);
                                        }
                                    }}
                                    className={classNames(
                                        classes.iconSizeCheckmark,
                                        { [classes.disabled]: !validator.hasUserPermissionForCp(site.cpId, PermissionTypes.ManageFlow) },
                                    )}
                                />
                            )}
                        </TableCell>
                    }
                    <TableCell className={classes.tableCellSpacerMobile} />
                </TableRow>
            );
        });
    }
}

function mapStateToProps(state) {
    return {
        auth: state.authentication,
        permissions: state.permissionsInfo.customerInfo
    };
}

SitesList.propTypes = {
    auth: PropTypes.object.isRequired,
    changeAlias: PropTypes.func.isRequired,
    changeColorHub: PropTypes.func.isRequired,
    messages: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    sites: PropTypes.array
};

export default connect(
    mapStateToProps, {
    changeAlias,
    changeColorHub
}
)(SitesList);