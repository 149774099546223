/**
 * WARNING: the GMG_STAGE_Config.json files are for convenience when running your local env only!!
 * The real build uses the data in AwsStages.js to generate a config using ConfigGenerator.js.
 * Making changes in these files directly will NOT affect the deployment!
 */
import GMG_Dev_Config from '../../../graphql/GMG_Dev_Config.json';
import GMG_Test_Config from '../../../graphql/GMG_Test_Config.json';
import GMG_Prod_Config from '../../../graphql/GMG_Prod_Config.json';
import { getAccessAndIdToken } from './SafeAuth';

const DevEndpoint = 'https://gmg-dev.aws.gmgcolor.com/v1/signed';

const local = {
    Tracking: {
        domain: "gmg-dev.aws.gmgcolor.com",
        enabled: true,
        ignore: [
            "@gmgcolor",
            "@colorproof"
        ]
    },
    Auth: {
        identityPoolId: "eu-central-1:eb3e40ec-7670-45aa-a0e2-5c7174056889",
        region: "eu-central-1",
        userPoolId: "eu-central-1_E2tf3yd45",
        userPoolWebClientId: "7rev0e2crj88iheavtv2r4ovpi"
    },
    CognitoHostedUIAuth: {
        identityPoolId: "eu-central-1:174fca7c-449f-4d5a-93ec-174ad7593659",
        region: "eu-central-1",
        userPoolId: "eu-central-1_1CiNIszlj",
        userPoolWebClientId: "cb2ijjap0v84g1tsjlpphka3d",
        oauth: {
            domain: "signin.dev.cdp.aws.gmgcolor.com",
            scope: [
                "email",
                "openid",
                "profile"
            ],
            redirectSignIn: "http://localhost:3000",
            redirectSignOut: "http://localhost:3000",
            responseType: "code"
        },
        roleArn: "arn:aws:iam::873185113971:role/GMG_Dev_AuthenticatedCustomer"
    },
    Storage: {
        AWSS3: {
            bucket: 'gmg-dev-cpgo-storage',
            region: 'eu-central-1'
        }
    },
    API: {
        endpoints: [
            {
                name: 'SignedAPI',
                endpoint: DevEndpoint,
                region: 'eu-central-1'
            }
        ]
    },
    FeatureFlags: {
        hideUserView: true,
        shouldShowUpdatedSystemView: true,
    }
};

let config = local;


if (process.env.REACT_APP_STAGE === 'Prod') {
    config = GMG_Prod_Config['config'];
} else if (process.env.REACT_APP_STAGE === 'Test') {
    config = GMG_Test_Config['config'];
} else if (process.env.REACT_APP_STAGE === 'Dev') {
    config = GMG_Dev_Config['config'];
}

// TODO: move it somewhere 
console.log("Enabling Cognito Hosted UI");
config.Auth.oauth = config.CognitoHostedUIAuth.oauth;
config.Auth = config.CognitoHostedUIAuth;

config.API.endpoints[0]['custom_header'] = async () => {
    const { idToken } = await getAccessAndIdToken();
    return {
        Authorization: idToken
    };
};



export default config;
