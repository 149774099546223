import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Amplify from 'aws-amplify';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './shared/Styles/styles';
import VBox from './RootComponents/VBox';
import MenuBar from './RootComponents/Menu/MenuBar';
import CpPrintersWithPrinters from './Printers/duck/graphql/CpPrintersWithData';
import CpJobsWithJobs from './Jobs/duck/graphql/cpJobsWithData';
import CpFlowOrders from './FlowOrders/CpFlowOrders';
import CpSettings from './Settings/CpSettings';
import withIntl from '../app/shared/Localization';
import * as constants from '../app/shared/duck/helpers/constants';
import ServiceContext from './shared/Services/ServiceContext';
import { logoutSuccess } from './Authentication/duck/actions/auth';
import { clearJobsData } from './Jobs/duck/jobsActions';
import { clearPrintersData } from './Printers/duck/printersActions';
import { unsubscribeFrom } from './shared/duck/helpers/subscriptions';
import {
    getFlows,
    getCustomerPairInfoLists,
    subscribeToAliasUpdates,
    subscribeToSitesUpdates,
    subscribeToFlowsUpdates,
    clearSharedData
} from './shared/duck/actions/pairingInfoActions';
import { loadCustomerInfo } from './shared/duck/actions/permissionsActions';
import AmplifyConfig from './shared/config/Amplify';
import { GMGAuth } from './shared/config/GMGAuth';
import { clearAuthTokens, saveAuthTokens } from './store/localStorage';
import { getAccessAndIdToken } from './shared/config/SafeAuth';

class AppRoot extends Component {
    static contextType = ServiceContext;

    timerConfig = null;
    state = {
        menuOpen: false
    };

    async componentDidMount() {
        const user = await GMGAuth.getInstance().getUserIfSignedIn();
        if (user === undefined) {
            this.context.navigationService.goTo(constants.GA_LOGIN);
            return;
        }

        await this.setAuthTokens();

        await this.fetchFlows();
        await this.fetchUsersAndSites();
        await this.props.loadCustomerInfo();

        //reinit amplify config, which should generate new access token
        this.setupTimerAmplifyReconfigure();

    }

    async componentDidUpdate(prevProps) {
        if (prevProps.auth.paired !== this.props.auth.paired) {
            if (this.props.sites.length === 0)
                await this.fetchUsersAndSites();
            if (this.props.flows.length === 0)
                await this.fetchFlows();

            return;
        }

        if (prevProps.flows.length !== this.props.flows.length)
            this.subscribeToFlowsUpdates();

        if (prevProps.sites.length !== this.props.sites.length ||
            prevProps.users.length !== this.props.users.length) {
            this.subscribeToSitesUpdates();
            this.subscribeToAliasUpdates();
        }
    }

    componentWillUnmount() {
        unsubscribeFrom(this.props.updateAliasSubscriptions);
        unsubscribeFrom(this.props.updateSitesSubscriptions);
        unsubscribeFrom(this.props.updateFlowsSubscriptions);
    }

    async fetchFlows() {
        await this.props.getFlows();

        if (!this.props.flows)
            return;

        this.subscribeToFlowsUpdates();
    }

    async fetchUsersAndSites() {
        await this.props.getCustomerPairInfoLists();

        if (!this.props.sites)
            return;

        this.subscribeToSitesUpdates();
        this.subscribeToAliasUpdates();
    }

    subscribeToFlowsUpdates() {
        unsubscribeFrom(this.props.updateFlowsSubscriptions);
        this.props.subscribeToFlowsUpdates(this.props.flows);
    }

    subscribeToSitesUpdates() {
        unsubscribeFrom(this.props.updateSitesSubscriptions);
        this.props.subscribeToSitesUpdates(this.props.sites);
    }

    subscribeToAliasUpdates() {
        unsubscribeFrom(this.props.updateAliasSubscriptions);
        this.props.subscribeToAliasUpdates(this.props.sites);
    }

    signOut = async () => {
        this.clearTimerAmplifyReconfigure();

        clearAuthTokens();

        this.props.logoutSuccess();

        await GMGAuth.getInstance().signOut();

        this.props.clearJobsData();
        this.props.clearPrintersData();
        this.props.clearSharedData();

    };

    setAuthTokens = async () => {
        const tokens = await getAccessAndIdToken();
        if (!tokens) {
            await this.signOut();
            return;
        }
        saveAuthTokens(tokens);

        AmplifyConfig.API.endpoints[0]['custom_header'] = () => ({
            Authorization: tokens.idToken
        });
        Amplify.configure(AmplifyConfig);
    };

    setupTimerAmplifyReconfigure = () => {
        const hour = 1000 * 60 * 60;
        this.timerConfig = setInterval(async () => await this.setAuthTokens(), hour);
    };

    clearTimerAmplifyReconfigure = () => {
        if (this.timerConfig)
            clearInterval(this.timerConfig);
    };


    render() {
        const { classes } = this.props;
        // console.log('AppRoot', this);
        // console.log('AppRoot this.props', this.props);
        // console.log('AppRoot.js env', process.env);

        return (
            <div>
                <MenuBar signOut={this.signOut} />
                <div
                    className={classNames(classes.rootMenu, {
                        [classes.blurBackground]: this.state.menuOpen
                    })}
                >
                    <VBox className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        <Route
                            exact
                            path={[constants.GA_HOME, constants.GA_JOBS, constants.GA_JOB_ID]}
                            render={() => <CpJobsWithJobs />}
                        />
                        <Route
                            exact
                            path={constants.GA_PRINTERS}
                            render={() => <CpPrintersWithPrinters />}
                        />
                        <Route
                            exact
                            path={constants.GA_FLOW_ORDERS}
                            render={() => <CpFlowOrders />}
                        />
                        <Route
                            path={[
                                constants.GA_SETTINGS_SYSTEMS,
                                constants.GA_SETTINGS_USERS,
                                constants.GA_SETTINGS_FLOWS
                            ]}
                            render={() =>
                                <CpSettings
                                    location={this.props.location}
                                    messages={this.props.messages}
                                />
                            }
                        />
                    </VBox>
                </div>
            </div>
        );
    }
}

// Maps Redux state to component props
function mapStateToProps(state) {
    return {
        auth: state.authentication,
        flows: state.pairingInfo.flows,
        users: state.pairingInfo.users,
        sites: state.pairingInfo.sites,
        updateAliasSubscriptions: state.pairingInfo.updateAliasSubscriptions,
        updateSitesSubscriptions: state.pairingInfo.updateSitesSubscriptions,
        updateFlowsSubscriptions: state.pairingInfo.updateFlowsSubscriptions
    };
}

// Maps dispatch functions to props
const mapDispatchToProps = {
    getFlows,
    getCustomerPairInfoLists,
    subscribeToAliasUpdates,
    subscribeToSitesUpdates,
    subscribeToFlowsUpdates,
    clearSharedData,
    clearJobsData,
    clearPrintersData,
    logoutSuccess,
    loadCustomerInfo
};

AppRoot.propTypes = {
    getFlows: PropTypes.func.isRequired,
    getCustomerPairInfoLists: PropTypes.func.isRequired,
    subscribeToAliasUpdates: PropTypes.func.isRequired,
    subscribeToSitesUpdates: PropTypes.func.isRequired,
    subscribeToFlowsUpdates: PropTypes.func.isRequired,
    clearJobsData: PropTypes.func.isRequired,
    clearSharedData: PropTypes.func.isRequired,
    clearPrintersData: PropTypes.func.isRequired,
    logoutSuccess: PropTypes.func.isRequired,
    loadCustomerInfo: PropTypes.func.isRequired
};

export default withIntl(
    withStyles(styles)(
        withRouter(
            connect(mapStateToProps, mapDispatchToProps)(AppRoot)
        )
    )
);