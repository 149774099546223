import gql from 'graphql-tag'
import axios from 'axios'
import { client, cpGoDocDbApi } from '../../shared/config/Clients'
import { getSignedUrlForImageSrc } from '../../shared/config/AwsS3Client'
import * as types from './types'
import {
  listCustomerHfWfs,
  listCustomerCpJobDetailsByJob,
  listCustomerCpJobs,
  getCertificate
} from '../../../graphql/queries'
import { listColorChannelMapping, listCustomerJobIds } from '../../../graphql/docuDbQueries'
import { onSaveChannelMappingFromS3 } from '../../../graphql/docuDbSubcriptions'
import { saveChannelMapping } from '../../../graphql/docuDBMutations'
import { createChannelForSaving } from './handlers/colorMappingHandlers'
import { addAction, customerCreateOrder } from '../../../graphql/mutations'
import {
  onCpAddJob,
  onCpDeleteJob,
  onCpUpdateJob
} from '../../../graphql/subscriptions'


export const getCustomerCpJobs = () => {
  return dispatch => {
    dispatch( {
      type: types.GET_CUSTOMER_CP_JOBS,
      loading: true
    } )
    return client.query( {
      query: gql( listCustomerCpJobs ),
      fetchPolicy: 'no-cache'
    } )
      .then( res => dispatch( { type: types.GET_CUSTOMER_CP_JOBS_SUCCESS, res } ) )
      .catch( err => dispatch( { type: types.GET_CUSTOMER_CP_JOBS_FAILURE, err } ) )
  }
}

export const subscribeToCpJobAdd = ( authorizedCps ) => {
  return dispatch => {
    dispatch( {
      type: types.SUBSCRIBE_TO_CP_JOB_ADD,
      loading: true
    } )
    let listOfSubsToCpJobAdd = []
    for ( let cpId of authorizedCps ) {
      if (listOfSubsToCpJobAdd[cpId] !== undefined) {
        // don't over-subscribe to duplicate cp IDs
        continue;
      }
      listOfSubsToCpJobAdd[ cpId ] = client.subscribe( {
        query: gql( onCpAddJob ),
        variables: { cpId: cpId }
      } )
        .subscribe(
          result => {
            dispatch( {
              type: types.SUBSCRIBE_TO_CP_JOB_ADD_SUCCESS,
              subscriptions: listOfSubsToCpJobAdd,
              data: [ result.data.onCpAddJob ]
            } )
          },
          error => dispatch( { type: types.SUBSCRIBE_TO_CP_JOB_ADD_FAILURE, err: error } )
        )
    }
    return
  }
}

export const subscribeToCpJobDelete = ( authorizedCps ) => {
  return dispatch => {
    dispatch( {
      type: types.SUBSCRIBE_TO_CP_JOB_DELETE,
      loading: true
    } )
    let deleteJobSubscriptions = []
    for ( let cpId of authorizedCps ) {
      deleteJobSubscriptions[ cpId ] = client.subscribe( {
        query: gql( onCpDeleteJob ),
        variables: { cpId: cpId }
      } )
        .subscribe(
          result => {
            dispatch( {
              type: types.SUBSCRIBE_TO_CP_JOB_DELETE_SUCCESS,
              subscriptions: deleteJobSubscriptions,
              data: result.data.onCpDeleteJob
            } )
          },
          error => dispatch( { type: types.SUBSCRIBE_TO_CP_JOB_DELETE_FAILURE, err: error } )
        )
    }
    return
  }
}

export const subscribeToCpJobUpdates = ( authorizedCps ) => {
  return dispatch => {
    dispatch( {
      type: types.SUBSCRIBE_TO_CP_JOB_UPDATE,
      loading: true
    } )

    let updateJobSubscriptions = []
    for ( let cpId of authorizedCps ) {
      if (updateJobSubscriptions[ cpId ] !== undefined) {
        // don't over-subscribe to duplicate cp IDs
        continue;
      }
      updateJobSubscriptions[ cpId ] = client.subscribe( {
        query: gql( onCpUpdateJob ),
        variables: { cpId: cpId }
      } )
        .subscribe(
          result => {
            dispatch( {
              type: types.SUBSCRIBE_TO_CP_JOB_UPDATE_SUCCESS,
              subscriptions: updateJobSubscriptions,
              data: result.data.onCpUpdateJob
            } )
          },
          error => dispatch( { type: types.SUBSCRIBE_TO_CP_JOB_UPDATE_FAILURE, err: error } )
        )
    }
    return
  }
}

export const subscribeToCpJobsWithChannelMapping = ( authorizedCps ) => {
  return dispatch => {
    dispatch( {
      type: types.SUBSCRIBE_TO_CP_JOB_CHANNEL_MAPPING,
      loading: true
    } )
  
    let colorMappingChannelJobSubscriptions = []
    for ( let cpId of authorizedCps ) {
      if (colorMappingChannelJobSubscriptions[ cpId ] !== undefined) {
        // don't over-subscribe to duplicate cp IDs
        continue;
      }
      colorMappingChannelJobSubscriptions[ cpId ] = cpGoDocDbApi.subscribe( {
        query: gql( onSaveChannelMappingFromS3 ),
        variables: { cpId },
      } )
        .subscribe(
          result => {
            dispatch( {
              type: types.SUBSCRIBE_TO_CP_JOB_CHANNEL_MAPPING_SUCCESS,
              subscriptions: colorMappingChannelJobSubscriptions,
              data: result.data.onSaveChannelMappingFromS3
            } )
          },
          error => {
            dispatch( { type: types.SUBSCRIBE_TO_CP_JOB_CHANNEL_MAPPING_FAILURE, err: error } )
          }
        )
    }
    return
  }
}

export const getCustomerHfWfs = () => {
  return dispatch => {
    dispatch( {
      type: types.LIST_CUSTOMER_HFWFS,
      loading: true
    } )
    return client.query( {
      query: gql( listCustomerHfWfs ),
      fetchPolicy: 'no-cache'
    } )
      .then( res => dispatch( { type: types.LIST_CUSTOMER_HFWFS_SUCCESS, res } ) )
      .catch( err => dispatch( { type: types.LIST_CUSTOMER_HFWFS_FAILURE, err } ) )
  }
}

export const getCustomerCpJobDetailsByJob = ( jobId ) => {
  return dispatch => {
    dispatch( {
      type: types.LIST_CUSTOMER_CP_JOB_DETAILS,
      loading: true
    } )
    return client.query( {
      query: gql( listCustomerCpJobDetailsByJob ),
      variables: { input: { jobId: jobId } },
      fetchPolicy: 'no-cache'
    } )
      .then( res => dispatch( { type: types.LIST_CUSTOMER_CP_JOB_DETAILS_SUCCESS, res } ) )
      .catch( err => dispatch( { type: types.LIST_CUSTOMER_CP_JOB_DETAILS_FAILURE, err } ) )
  }
}

export const getColorChannelMapping = ( jobId, frameId ) => {
  return dispatch => {
    dispatch({
      type: types.GET_COLOR_CHANNEL_MAPPING_DETAILS,
      loading: true
    })
    return cpGoDocDbApi.query({
      query: gql(listColorChannelMapping),
      variables: { jobId, frameId },
      fetchPolicy: 'no-cache'
    }).then(
      res => dispatch({
        type: types.GET_COLOR_CHANNEL_MAPPING_DETAILS_SUCCESS, res
      })
    ).catch(
      err => dispatch({
        type: types.GET_COLOR_CHANNEL_MAPPING_DETAILS_FAILURE, err
      })
    )
  }
}

export const getCustomerJobsIds= ( cpIds ) => {
  /// it's possible for there to be duplicate cpId's in the list of cpId's for this user.
  /// this GQL call is satisfied by a partiql query which checks if the indexed field (cpId)
  /// is contained in the array of arguments (cpId's we pass from here).
  /// if there are duplicates in that array, GQL returns an error:
  /// (overlapping conditions with range keys are not supported in where clause). 
  /// in this case, edit button remains disabled. Fixed by ensuring we only pass unique cpId's.
  const uniqueCpIds = [...new Set(cpIds)];
  return dispatch => {
    dispatch({
      type: types.GET_CUSTOMER_CP_JOB_IDS,
      loading: true
    })
    return cpGoDocDbApi.query({
      query: gql(listCustomerJobIds),
      variables: { cpIds: uniqueCpIds },
      fetchPolicy: 'no-cache'
    }).then( res => {
        return dispatch({type: types.GET_CUSTOMER_CP_JOB_IDS_SUCCESS, res})
    }).catch( err => {
        console.log('getCustomerJobsIds query ERROR response: ', err)
        return dispatch({type: types.GET_CUSTOMER_CP_JOB_IDS_FAILURE, err})
    })
  }
}

export const saveColorChannelMapping = (jobId, frameId, cpId, colorChannelMapping, channelValuesFromState) => {

  const channelsToSave = createChannelForSaving(colorChannelMapping, channelValuesFromState)

  return dispatch => {
    dispatch({
      type: types.SAVE_CHANNEL_MAPPING
    })
    return cpGoDocDbApi.mutate({
      mutation: gql(saveChannelMapping),
      variables: {
        input: {
          jobId: jobId,
          frameId: frameId,
          cpId: cpId,
          json: {
            channels: channelsToSave
          }
        }
      }
    })
      .then(res => dispatch({ type: types.SAVE_CHANNEL_MAPPING_SUCCESS, res }))
      .catch(err => dispatch({ type: types.SAVE_CHANNEL_MAPPING_FAILURE, err }))
  }
}

export const getCpCertificate = cpId => {
  return dispatch => {
    dispatch( {
      type: types.GET_CP_CERTIFICATE,
      loading: true
    } )
    return client.query( {
      query: gql( getCertificate ),
      variables: { input: { cpId: cpId } },
      fetchPolicy: 'no-cache'
    } )
      .then( res => dispatch( { type: types.GET_CP_CERTIFICATE_SUCCESS, res } ) )
      .catch( err => dispatch( { type: types.GET_CP_CERTIFICATE_FAILURE, err } ) )
  }
}

export const getImageDetails = ( url ) => {
  return dispatch => {
    dispatch( {
      type: types.GET_IMAGE_DETAILS,
      loading: true
    } )
    return axios.get( url )
      .then( res => dispatch( { type: types.GET_IMAGE_DETAILS_SUCCESS, res } ) )
      .catch( err => dispatch( { type: types.GET_IMAGE_DETAILS_FAILURE, err } ) )
  }
}

export const mutateAddAction = ( actionInput ) => {
  return dispatch => {
    dispatch( {
      type: types.ADD_ACTION,
      loading: true
    } )
    return client.mutate( {
      mutation: gql( addAction ),
      variables: { input: actionInput }
    } )
      .then( res => dispatch( { type: types.ADD_ACTION_SUCCESS, res } ) )
      .catch( err => dispatch( { type: types.ADD_ACTION_FAILURE, err } ) )
  }
}

export const mutateCustomerCreateOrder = ( orderInput ) => {
  return dispatch => {
    dispatch( {
      type: types.CUSTOMER_CREATE_ORDER,
      loading: true
    } )
    return client.mutate( {
      mutation: gql( customerCreateOrder ),
      variables: { input: orderInput }
    } )
      .then( res => dispatch( { type: types.CUSTOMER_CREATE_ORDER_SUCCESS, res } ) )
      .catch( err => dispatch( { type: types.CUSTOMER_CREATE_ORDER_FAILURE, err } ) )
  }
}

export const downloadJobDetailsImages = ( jobDetails ) => {
  return async dispatch => {
    dispatch( {
      type: types.DOWNLOAD_JOBDETAILS_IMAGE
    } )

    try {
      const promises = jobDetails.map(item => getSignedUrlForImageSrc(item, '.jpg'));
      let jobDetailsImages = await Promise.all( promises );

      dispatch( { type: types.DOWNLOAD_JOBDETAILS_IMAGE_SUCCESS, jobDetailsImages } )
    }
    catch ( err ) {
      dispatch( { type: types.DOWNLOAD_JOBDETAILS_IMAGE_FAILURE, err } )
    }

    return
  }
}

export const downloadSingleJobDetailsImage = ( params ) => {
  return async dispatch => {
    dispatch( {
      type: types.DOWNLOAD_JOBDETAILS_FILE
    } )

    try {
      let fileUrl = await getSignedUrlForImageSrc( params, '.json' )

      dispatch( { type: types.DOWNLOAD_JOBDETAILS_FILE_SUCCESS, fileUrl } )
    }
    catch ( err ) {
      dispatch( { type: types.DOWNLOAD_JOBDETAILS_FILE_FAILURE, err } )
    }

    return
  }
}

export const clearJobsData = () => {
  return dispatch => {
    dispatch( {
      type: types.CLEAR_JOBS_DATA
    } )
  }
}