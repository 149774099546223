import Plausible from 'plausible-tracker';
import AmplifyConfig from '../config/Amplify';
import Utils from '../duck/helpers/Utils';
import { TrackingEvent } from './tracking';

class TrackingService {
    private static instance: TrackingService | null = null;
    private static plausibleInstance: ReturnType<typeof Plausible> | null = null
    private static isColorCards = false;

    private constructor() {
        // Private constructor to prevent direct instantiation
        if (TrackingService.instance) {
            throw new Error("Singleton class already instantiated. Use getInstance.");
        }

        TrackingService.initVariant();
        TrackingService.plausibleInstance = Plausible({
            domain: AmplifyConfig.Tracking.domain,
            trackLocalhost: true,
        });
        TrackingService.plausibleInstance.enableAutoPageviews()
    }

    public static getInstance(): TrackingService {
        if (!TrackingService.instance) {
            console.log('instantiate TrackingService singleton ....')
            TrackingService.instance = new TrackingService();
        }
        return TrackingService.instance;
    }

    private static initVariant() {
        if (Utils.isColorCard()) {
            TrackingService.isColorCards = true;
        }
    }

    isInitialized(): boolean {
        return TrackingService.instance !== null && !!TrackingService.plausibleInstance;
    }

    trackPage(): void {
        // TODO: remove this if we know what do we want to with colorCard
        // if (TrackingService.isColorCards) {
        //     ReactGA.pageview('/cc' + path);
        // } else {
        //     ReactGA.pageview(path);
        // }
    }
    trackEvent(event: TrackingEvent): void {
        if (!TrackingService.instance || !TrackingService.plausibleInstance) {
            console.log('Tracking Service is not inistialized properly => ', TrackingService.instance);
            console.log('Plausible is not inistialized properly => ', TrackingService.plausibleInstance);
            return;
        }
        TrackingService.plausibleInstance.trackEvent(event.name,  {
            props: {
                ...(event.description 
                    ? { description: event.description } 
                    : {}
                )
            },
        });
     
        // TODO: remove this if we know what do we want to with colorCard
        // ReactGA.event({
        //   category: this._isColorCards ? 'CC ' + category : category,
        //   action: this._isColorCards ? 'CC ' + action : action,
        //   label: label,
        //   value: value
        // })
    }
}

export { TrackingService };