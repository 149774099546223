import Utils from '../helpers/Utils'

export const mapLicenseSystemsToSitesListProps = (licenseSystems) => licenseSystems.map(ls => {
    return {
            site: ls.computerName || 'no data',
            dongleId: ls.licenseSystemId,
            licensed: ls.isLicensed,
            isOnline: ls.isOnline,
            lastSeen: ls.isOnline 
                ? new Date().now()
                : ls.onlineStatusChangeTimestamp 
                    ? new Date(ls.onlineStatusChangeTimestamp).getTime() 
                    : null,
            isHub: ls.isHub,
            alias: ls.location || 'no data',
            cpId: 'no data',
        }
    })

export const handleUsersChange = customerPairInfo => {
  let users = []
  customerPairInfo.forEach(pairInfo => {
   ( pairInfo.customers || [] ).forEach(customer => {
      let localUser = {
        cpId: pairInfo.cpId,
        site: pairInfo.site || pairInfo.dongleId,
        customerId: customer.customerId,
        dongleId: pairInfo.dongleId,
        email: customer.email,
        lastlogin: customer.lastlogin,
        role: customer.role,
        isPending: customer.isPending
      }
      users.push(localUser)
    })
  })
  return users
}

export const handleSitesChange = (customerPairInfo) => {
  let sites = []
  customerPairInfo.forEach(value => {
    let site = {
      site: value.site || value.dongleId,// If the site is not set, use the dongleId instead.
      licensed: value.licensed,
      dongleId: value.dongleId,
      lastSeen: sanitizeLastSeen(value.lastSeen),
      alias: value.alias,
      isHub: value.isHub,
      cpId: value.cpId,
      version: value.version,
      role: value.role,
      customers: value.customers,
      flowLicensed: value.flowLicensed
    }

    if (value.hasOwnProperty('isOnline') && value.isOnline != null)
      site.isOnline = value.isOnline

    if (!sites.some(el => el.site === site.site && el.dongleId === site.dongleId))
      sites.push(site)
  })
  return sites
}

export const handleFlowsChange = listFlows => {
  return listFlows.map(flow => {
    return {
      id: flow.id,
      hub: flow.hubId,
      name: flow.name,
      customerId: flow.customerId,
      created: flow.created,
      clients: flow.clients,
      wfId: flow.targetHfwfId,
      wfName: flow.targetWfName
    }
  })
}

export const handleSiteUpdate = (sites, updatedSites) => {
  let allSites = sites.slice()
  return allSites.map(site => {
    const newSite = updatedSites.find(us => us.dongleId === site.dongleId)
    if(!newSite)
      return site

    if (newSite.hasOwnProperty('isOnline') && newSite.isOnline != null)
      site.isOnline = newSite.isOnline

    if (newSite.hasOwnProperty('isOnline') && newSite.isOnline != null && newSite.isOnline)
      site.lastSeen = new Date().getTime()
    else// Here somehow a comma within the timestamp is sent...
      site.lastSeen = sanitizeLastSeen(newSite.lastSeen)

    if (!newSite.site) {
      // Support old schema, use the dongleId as site.
      site.site = newSite.dongleId
    }

    return site
  })
}

export const handleAliasUpdate = (sites, updatedSites) => {
  let allSites = sites.slice()
  return allSites.map(site => {
    const updatedSite = updatedSites.find(s => s.dongleId === site.dongleId && s.cpId === site.cpId)
    if (updatedSite) {
      site.isHub = updatedSite.isHub
      site.alias = updatedSite.alias

      if (!updatedSite.site) {
        // Support old schema, use the dongleId as site.
        site.site = updatedSite.dongleId
      }
    }
    return site
  })
}

export const handleFlowUpdate = (allflows, newFlow) => {
  //TODO: complete implementation
  let flows = Utils.deepClone(allflows)
  return flows
}

export const handleUserUpdate = (users, updatedUser) => {
  let allUsers = users.slice()
  return allUsers.map(user => {
    if (user.customerId === updatedUser.customerId && user.cpId === updatedUser.cpId)
      user.role = updatedUser.role

    return user
  })
}

export const augmentFlowsWithSiteData = (flows, sites) => {
  let augmentedFlows = []

  if (flows && sites) {
    // Filter a cpId => siteName object from the sites object

    let cpIdToSiteName = {}

    for (let site of sites) {
      cpIdToSiteName[site.cpId] = site.alias ? site.alias : site.site
    }

    for (let flow of flows) {
      if (cpIdToSiteName.hasOwnProperty(flow.hub)) {
        flow.hubName = cpIdToSiteName[flow.hub]
      }
      if (flow.clients && flow.clients.length > 0) {
        flow.clientsWithName = []

        for (let clientCpId of flow.clients) {
          flow.clientsWithName.push({
            cpId: clientCpId,
            name: cpIdToSiteName[clientCpId]
          })
        }
      }
      augmentedFlows.push(flow)
    }

    return augmentedFlows
  }

  return flows.slice()
}

const sanitizeLastSeen = (lastSeen) => {
  if (!lastSeen) {
    return null
  }

  let s1 = lastSeen.split(',')[0]
  return s1.split('.')[0]
}