import * as types from '../types/pairingInfoTypes'
import {
  handleFlowsChange,
  handleSitesChange,
  handleUsersChange,
  handleSiteUpdate,
  handleAliasUpdate,
  handleFlowUpdate,
  handleUserUpdate,
  augmentFlowsWithSiteData,
  mapLicenseSystemsToSitesListProps
} from '../handlers/dataHandler'

let initialState = {
  flows: [],
  sites: [],
  users: [],
  updateAliasSubscriptions: [],
  updateSitesSubscriptions: [],
  updateFlowsSubscriptions: [],
  licenseSystemsFromNewBackend: [],
  graphQLErrors: null
}

export default function pairingInfo (state = initialState, action) {
  switch (action.type) {
    case types.GET_FLOWS_SUCCESS:
      let flows = handleFlowsChange(action.res.data.listFlows.items || [])
      return {
        ...state,
        flows: augmentFlowsWithSiteData(flows, state.sites),
        graphQLErrors: action.err
      }
    case types.GET_LICENSESYSTEMS_NEW_BE_SUCCESS:
        const result =  mapLicenseSystemsToSitesListProps(action.res.data.getLicenseSystemsByCurrentUser.licenseSystems)
        console.log('GET_LICENSESYSTEMS_NEW_BE_SUCCESS', result)
      return {
        ...state,
        licenseSystemsFromNewBackend: result || [],
        graphQLErrors: action.err,
      }
    case types.GET_CUSTOMER_PAIR_INFO_SUCCESS:
      const allSites = handleSitesChange(action.res.data.getCustomerPairInfo.items || [])
      return {
        ...state,
        users: handleUsersChange(action.res.data.getCustomerPairInfo.items || []),
        sites: allSites,
        flows: augmentFlowsWithSiteData(state.flows, allSites),
        graphQLErrors: action.err,
      }
    case types.SUBSCRIBE_TO_CUSTOMER_UPDATE_CP_SITE_SUCCESS:
      return {
        ...state,
        sites: handleAliasUpdate(state.sites, action.data),
        updateAliasSubscriptions: action.subscriptions,
        graphQLErrors: action.err
      }
    case types.SUBSCRIBE_TO_FLOWS_UPDATE_SUCCESS:
      let updatedFlows = handleFlowUpdate(state.flows, action.data)
      return {
        ...state,
        flows: augmentFlowsWithSiteData(updatedFlows, state.sites),
        updateFlowsSubscriptions: action.subscriptions,
        graphQLErrors: action.err
      }
    case types.SUBSCRIBE_TO_LINKED_CP_INFO_UPDATE_SUCCESS:
      const updatedSites = handleSiteUpdate(state.sites, action.data)
      return {
        ...state,
        sites: updatedSites,
        flows: augmentFlowsWithSiteData(state.flows, updatedSites),
        updateSitesSubscriptions: action.subscriptions,
        graphQLErrors: action.err
      }
    case types.DELETE_CP_CUSTOMER_SUCCESS:
      return {
        ...state,
        users: state.users.filter(user => `${user.customerId}_${user.cpId}` !== action.res.data.deleteCpCustomer.CustomerCpPair),
        graphQLErrors: action.err
      }
    case types.DELETE_PENDING_CP_CUSTOMER_SUCCESS:
      return {
        ...state,
        users: state.users.filter(user => `${user.customerId}_${user.cpId}` !== action.res.data.deletePendingCpCustomer.CustomerCpPair),
        graphQLErrors: action.err
      }
    case types.ADD_FLOW_SUCCESS:
      let addFlow = handleFlowsChange([ action.res.data.addFlow ])
      let augmentedFlows = augmentFlowsWithSiteData([ ...addFlow, ...state.flows ], state.sites)
      return {
        ...state,
        flows: augmentedFlows
      }
    case types.INVITE_USER_FAILURE:
      return {
        ...state,
        graphQLErrors: action.err.message
      }
    case types.DELETE_FLOW_SUCCESS:
      return {
        ...state,
        flows: state.flows.filter(flow => flow.id !== action.res.data.deleteFlow.id)
      }
    case types.UPDATE_CP_CUSTOMER_PAIR_SUCCESS:
      return {
        ...state,
        users: handleUserUpdate(state.users, action.res.data.updateCustomerPair)
      }
    case types.SUBSCRIBE_TO_CUSTOMER_UPDATE_CP_SITE:
    case types.SUBSCRIBE_TO_FLOWS_UPDATE:
    case types.SUBSCRIBE_TO_LINKED_CP_INFO_UPDATE:
    case types.ADD_FLOW:
    case types.INVITE_USER:
    case types.CHANGE_ALIAS:
    case types.CHANGE_COLOR_HUB:
    case types.UPDATE_CP_CUSTOMER_PAIR:
    case types.DELETE_CP_CUSTOMER:
    case types.DELETE_PENDING_CP_CUSTOMER:
    case types.DELETE_FLOW:
      return {
        ...state,
        graphQLErrors: null
      }
    case types.CLEAR_SHARED_DATA:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}