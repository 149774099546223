export const listColorChannelMapping = `query getColorChannelMapping(
    $jobId: String!, $frameId: String!
) { getColorChannelMapping(jobId: $jobId, frameId: $frameId){
    availableColors
    cmyk {
      c
      k
      m
      y
    }
    enabled
    id
    lab {
      a
      b
      l
    }
    name
    printOrder
    replaceColorName
    selectedColor
  }
}`;

export const listCustomerJobIds = `query findJobs($cpIds: [String]!) {
  findJobs(cpIds: $cpIds) 
}`;

export const getPairedLicenseSystemsByUser = `query  GetLicenseSystemsByCurrentUser {
    getLicenseSystemsByCurrentUser {
      licenseSystems {
        computerName
        isHub
        isLicensed
        isOnline
        lastChangeDate
        licenseSystemId
        location
        onlineStatusChangeTimestamp
      }
    }
  }
`;